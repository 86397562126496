.guide-wrapper {
  width: 100vw;
  height: 100vh;
  background-image: url("./images/content_bg.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}

.guide-logo {
  padding: 36px 0 0 40px;
}

.guide-logo img {
  width: 160px;
  height: 48px;
}

.guide-content {
  display: flex;
  /* padding-left: 100px; */
  box-sizing: border-box;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.left_content {
  display: flex;
  flex-direction: column;
  gap: 56px;
  box-sizing: border-box;
}

.left_item {
  width: 440px;
  height: 168px;
  background-image: linear-gradient(270deg, #2781e700 0%, #2781e72e 100%);
  border-radius: 12px;
  display: flex;
  padding-left: 40px;
  padding-top: 35px;
  box-sizing: border-box;
}

.left_item_text_title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  line-height: 33px;
}

.left_item_text_desc {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 5px;
  line-height: 25px;
}

.left_item img {
  width: 40px;
  height: 40px;
  margin-right: 18px;
}

.right_content {
  /* flex: 1; */
  margin-left: 40px;
  display: flex;
  flex-direction: column;
}

.right_content_header {
  display: flex;
  gap: 40px;
}

.right_content_header_item {
  width: 372px;
  height: 56px;
  background: #ffffff;
  border: 1.5px solid #006ae1;
  border-radius: 10px;
  box-sizing: border-box;
}

.right_content_header_item_text {
  line-height: 56px;
  text-align: center;
  color: #006ae1;
  font-size: 24px;
  font-weight: 500;
}

.right_content_main {
  display: flex;
  margin-top: 20px;
  gap: 40px;
  position: relative;
}
.right_content_main_left {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.right_content_main_item {
  width: 372px;
  height: 250px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;

  text-decoration: none;
}

.right_content_main_item.large {
  height: 540px;
  padding-top: 188px;
}
.right_content_main_item.large .right_content_main_item_title {
  margin-top: 30px;
}

.right_content_main_item_title {
  font-size: 26px;
  color: #333333;
  font-weight: 500;
  line-height: 37px;
  margin-top: 16px;
  text-decoration: none;
}

.long_title{
  font-size: 24px;
}

.right_content_main_item_desc {
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  margin-top: 10px;
  text-decoration: none;
}

.right_content_main_item img {
  width: 84px;
  height: 84px;
}

.line1 {
  position: absolute;
  top: 149px;
  left: 372px;
}

.line2 {
  position: absolute;
  top: 300px;
  left: 372px;
}

.line3 {
  position: absolute;
  top: 149px;
  left: 784px;
}

.line4 {
  position: absolute;
  top: 300px;
  left: 784px;
}
